import React from "react";

import styled, { keyframes } from "styled-components";

import { ReactComponent as AiBotIcon } from "assets/svg/icons/AIBotIcon.svg";
import { ReactComponent as AiResponseIcon } from "assets/svg/icons/AIResponseIcon.svg";
import { ReactComponent as AiIcon } from "assets/svg/icons/AiIcon.svg";
import { ReactComponent as AlertIcon } from "assets/svg/icons/AlertIcon.svg";
import { ReactComponent as AlignCenterIcon } from "assets/svg/icons/AlignCenterIcon.svg";
import { ReactComponent as AlignLeftIcon } from "assets/svg/icons/AlignLeftIcon.svg";
import { ReactComponent as AlignRightIcon } from "assets/svg/icons/AlignRightIcon.svg";
import { ReactComponent as ArrowDownIcon } from "assets/svg/icons/ArrowDownIcon.svg";
import { ReactComponent as ArrowLeftDoubleIcon } from "assets/svg/icons/ArrowLeftDoubleIcon.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/svg/icons/ArrowLeftIcon.svg";
import { ReactComponent as ArrowRightDoubleIcon } from "assets/svg/icons/ArrowRightDoubleIcon.svg";
import { ReactComponent as ArrowRightIcon } from "assets/svg/icons/ArrowRightIcon.svg";
import { ReactComponent as ArrowUpIcon } from "assets/svg/icons/ArrowUpIcon.svg";
import { ReactComponent as AscendingIcon } from "assets/svg/icons/AscendingIcon.svg";
import { ReactComponent as BookmarkDisactiveIcon } from "assets/svg/icons/BookmarkDisactiveIcon.svg";
import { ReactComponent as BookmarkIcon } from "assets/svg/icons/BookmarkIcon.svg";
import { ReactComponent as BotCalibrationIcon } from "assets/svg/icons/BotCalibrationIcon.svg";
import { ReactComponent as BotReduceIcon } from "assets/svg/icons/BotReduceIcon.svg";
import { ReactComponent as BotRelayIcon } from "assets/svg/icons/BotRelayIcon.svg";
import { ReactComponent as BotStretchIcon } from "assets/svg/icons/BotStretchIcon.svg";
import { ReactComponent as BotTranslateIcon } from "assets/svg/icons/BotTranslateIcon.svg";
import { ReactComponent as BulletIcon } from "assets/svg/icons/BulletIcon.svg";
import { ReactComponent as CancelHoverIcon } from "assets/svg/icons/CancelHoverIcon.svg";
import { ReactComponent as CancelIcon } from "assets/svg/icons/CancelIcon.svg";
import { ReactComponent as CaptureIcon } from "assets/svg/icons/CaptureIcon.svg";
import { ReactComponent as CardViewIcon } from "assets/svg/icons/CardViewIcon.svg";
import { ReactComponent as CheckIcon } from "assets/svg/icons/CheckIcon.svg";
import { ReactComponent as CheckboxActiveDisabledIcon } from "assets/svg/icons/CheckboxActiveDisabledIcon.svg";
import { ReactComponent as CheckboxActiveIcon } from "assets/svg/icons/CheckboxActiveIcon.svg";
import { ReactComponent as CheckboxDisactiveDisabledIcon } from "assets/svg/icons/CheckboxDisactiveDisabledIcon.svg";
import { ReactComponent as CheckboxDisactiveIcon } from "assets/svg/icons/CheckboxDisactiveIcon.svg";
import { ReactComponent as CheckboxTriStateDisabledIcon } from "assets/svg/icons/CheckboxTriStateDisabledIcon.svg";
import { ReactComponent as CheckboxTriStateIcon } from "assets/svg/icons/CheckboxTriStateIcon.svg";
import { ReactComponent as ConnectOffIcon } from "assets/svg/icons/ConnectOffIcon.svg";
import { ReactComponent as ConnectOnIcon } from "assets/svg/icons/ConnectOnIcon.svg";
import { ReactComponent as CopyCutIcon } from "assets/svg/icons/CopyCutIcon.svg";
import { ReactComponent as CopyIcon } from "assets/svg/icons/CopyIcon.svg";
import { ReactComponent as CopyWithStyleIcon } from "assets/svg/icons/CopyWithStyleIcon.svg";
import { ReactComponent as DeleteIcon } from "assets/svg/icons/DeleteIcon.svg";
import { ReactComponent as DescendingIcon } from "assets/svg/icons/DescendingIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/svg/icons/DownloadIcon.svg";
import { ReactComponent as EnterIcon } from "assets/svg/icons/EnterIcon.svg";
import { ReactComponent as ExpandIcon } from "assets/svg/icons/ExpandIcon.svg";
import { ReactComponent as FileUploadIcon } from "assets/svg/icons/FileUploadIcon.svg";
import { ReactComponent as FileUploadOffIcon } from "assets/svg/icons/FileUploadOffIcon.svg";
import { ReactComponent as FileUploadOnIcon } from "assets/svg/icons/FileUploadOnIcon.svg";
import { ReactComponent as FoldAllIcon } from "assets/svg/icons/FoldAllIcon.svg";
import { ReactComponent as FolderDownwardIcon } from "assets/svg/icons/FolderDownwardIcon.svg";
import { ReactComponent as FolderIcon } from "assets/svg/icons/FolderIcon.svg";
import { ReactComponent as FolderRightwardIcon } from "assets/svg/icons/FolderRightwardIcon.svg";
import { ReactComponent as HamburgerIcon } from "assets/svg/icons/HamburgerIcon.svg";
import { ReactComponent as ImageBlockIcon } from "assets/svg/icons/ImageBlockIcon.svg";
import { ReactComponent as InfoIcon } from "assets/svg/icons/InfoIcon.svg";
import { ReactComponent as KnobIcon } from "assets/svg/icons/KnobIcon.svg";
import { ReactComponent as LastIcon } from "assets/svg/icons/LastIcon.svg";
import { ReactComponent as LinkIcon } from "assets/svg/icons/LinkIcon.svg";
import { ReactComponent as ListIcon } from "assets/svg/icons/ListIcon.svg";
import { ReactComponent as ListViewIcon } from "assets/svg/icons/ListViewIcon.svg";
import { ReactComponent as LoadIcon } from "assets/svg/icons/LoadIcon.svg";
import { ReactComponent as LogoutIcon } from "assets/svg/icons/LogoutIcon.svg";
import { ReactComponent as MemoIcon } from "assets/svg/icons/MemoIcon.svg";
import { ReactComponent as MinusIcon } from "assets/svg/icons/MinusIcon.svg";
import { ReactComponent as ModifyIcon } from "assets/svg/icons/ModifyIcon.svg";
import { ReactComponent as NewTabOpenIcon } from "assets/svg/icons/NewTabOpenIcon.svg";
import { ReactComponent as OpenAllIcon } from "assets/svg/icons/OpenAllIcon.svg";
import { ReactComponent as OptionIcon } from "assets/svg/icons/OptionIcon.svg";
import { ReactComponent as PDFIcon } from "assets/svg/icons/PDFIcon.svg";
import { ReactComponent as PlayIcon } from "assets/svg/icons/PlayIcon.svg";
import { ReactComponent as PlusIcon } from "assets/svg/icons/PlusIcon.svg";
import { ReactComponent as PrintIcon } from "assets/svg/icons/PrintIcon.svg";
import { ReactComponent as PublisherIcon } from "assets/svg/icons/PublisherIcon.svg";
import { ReactComponent as PwHideIcon } from "assets/svg/icons/PwHideIcon.svg";
import { ReactComponent as PwShowIcon } from "assets/svg/icons/PwShowIcon.svg";
import { ReactComponent as QuestionIcon } from "assets/svg/icons/QuestionIcon.svg";
import { ReactComponent as QuoteAddIcon } from "assets/svg/icons/QuoteAddIcon.svg";
import { ReactComponent as QuoteIcon } from "assets/svg/icons/QuoteIcon.svg";
import { ReactComponent as QuoteRemoveIcon } from "assets/svg/icons/QuoteRemoveIcon.svg";
import { ReactComponent as RefreshIcon } from "assets/svg/icons/RefreshIcon.svg";
import { ReactComponent as ReleaseIcon } from "assets/svg/icons/ReleaseIcon.svg";
import { ReactComponent as SaveIcon } from "assets/svg/icons/SaveIcon.svg";
import { ReactComponent as SearchIcon } from "assets/svg/icons/SearchIcon.svg";
import { ReactComponent as SettingIcon } from "assets/svg/icons/SettingIcon.svg";
import { ReactComponent as SideBarReferenceIcon } from "assets/svg/icons/SideBarReferenceIcon.svg";
import { ReactComponent as SideBarResearchIcon } from "assets/svg/icons/SideBarResearchIcon.svg";
import { ReactComponent as SideBarResearchItemIcon } from "assets/svg/icons/SideBarResearchItemIcon.svg";
import { ReactComponent as SideBarSearchIcon } from "assets/svg/icons/SideBarSearchIcon.svg";
import { ReactComponent as SidemenuCloseIcon } from "assets/svg/icons/SidemenuCloseIcon.svg";
import { ReactComponent as SidemenuOpenIcon } from "assets/svg/icons/SidemenuOpenIcon.svg";
import { ReactComponent as StarIcon } from "assets/svg/icons/StarIcon.svg";
import { ReactComponent as StopIcon } from "assets/svg/icons/StopIcon.svg";
import { ReactComponent as StyleBulletListIcon } from "assets/svg/icons/StyleBulletListIcon.svg";
import { ReactComponent as StyleHeader1Icon } from "assets/svg/icons/StyleHeader1Icon.svg";
import { ReactComponent as StyleHeader2Icon } from "assets/svg/icons/StyleHeader2Icon.svg";
import { ReactComponent as StyleHeader3Icon } from "assets/svg/icons/StyleHeader3Icon.svg";
import { ReactComponent as StyleNormalTextIcon } from "assets/svg/icons/StyleNormalTextIcon.svg";
import { ReactComponent as StyleTextBgColorIcon } from "assets/svg/icons/StyleTextBgColorIcon.svg";
import { ReactComponent as StyleTextColorIcon } from "assets/svg/icons/StyleTextColorIcon.svg";
import { ReactComponent as StyleToggleListIcon } from "assets/svg/icons/StyleToggleListIcon.svg";
import { ReactComponent as SubmenuIcon } from "assets/svg/icons/SubmenuIcon.svg";
import { ReactComponent as TagIcon } from "assets/svg/icons/TagIcon.svg";
import { ReactComponent as TextBlockIcon } from "assets/svg/icons/TextBlockIcon.svg";
import { ReactComponent as TextStyleIcon } from "assets/svg/icons/TextStyleIcon.svg";
import { ReactComponent as ThreadIcon } from "assets/svg/icons/ThreadIcon.svg";
import { ReactComponent as UserFillIcon } from "assets/svg/icons/UserFillIcon.svg";
import { ReactComponent as UserIcon } from "assets/svg/icons/UserIcon.svg";
import { ReactComponent as WarnIcon } from "assets/svg/icons/WarnIcon.svg";
import { ReactComponent as XIcon } from "assets/svg/icons/XIcon.svg";

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const Components = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    animation: ${rotate} 2s linear infinite;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        fill: ${(props) => props.color};
        transition: fill 120ms ease-in;
    }

    &:hover svg {
        fill: ${(props) => props.hoverColor || props.color};
    }
`;

function Icon({ name, size = "10px", color = "var(--color-Black)", hoverColor, ...rest }) {
    const commonProps = { width: size, height: size, fill: color };

    const IconComponent = (() => {
        switch (name) {
            case "aiBot":
                return AiBotIcon;
            case "aiResponse":
                return AiResponseIcon;
            case "ai":
                return AiIcon;
            case "alert":
                return AlertIcon;
            case "alignCenter":
                return AlignCenterIcon;
            case "alignLeft":
                return AlignLeftIcon;
            case "alignRight":
                return AlignRightIcon;
            case "arrowDown":
                return ArrowDownIcon;
            case "arrowLeftDouble":
                return ArrowLeftDoubleIcon;
            case "arrowLeft":
                return ArrowLeftIcon;
            case "arrowRightDouble":
                return ArrowRightDoubleIcon;
            case "arrowRight":
                return ArrowRightIcon;
            case "arrowUp":
                return ArrowUpIcon;
            case "asending":
                return AscendingIcon;
            case "bookmark":
                return BookmarkIcon;
            case "bookmarkDisactive":
                return BookmarkDisactiveIcon;
            case "botCalibration":
                return BotCalibrationIcon;
            case "botReduce":
                return BotReduceIcon;
            case "botRelay":
                return BotRelayIcon;
            case "botStretch":
                return BotStretchIcon;
            case "botTranslate":
                return BotTranslateIcon;
            case "bullet":
                return BulletIcon;
            case "cancel":
                return CancelIcon;
            case "cancelHover":
                return CancelHoverIcon;
            case "capture":
                return CaptureIcon;
            case "cardview":
                return CardViewIcon;
            case "check":
                return CheckIcon;
            case "checkboxActive":
                return CheckboxActiveIcon;
            case "checkboxActiveDisabled":
                return CheckboxActiveDisabledIcon;
            case "checkboxTriState":
                return CheckboxTriStateIcon;
            case "checkboxTriStateDisabled":
                return CheckboxTriStateDisabledIcon;
            case "checkboxDisactive":
                return CheckboxDisactiveIcon;
            case "checkboxDisactiveDisabled":
                return CheckboxDisactiveDisabledIcon;
            case "connectOff":
                return ConnectOffIcon;
            case "connectOn":
                return ConnectOnIcon;
            case "copy":
                return CopyIcon;
            case "copyWithStyle":
                return CopyWithStyleIcon;
            case "copyCut":
                return CopyCutIcon;
            case "delete":
                return DeleteIcon;
            case "desending":
                return DescendingIcon;
            case "download":
                return DownloadIcon;
            case "enter":
                return EnterIcon;
            case "expand":
                return ExpandIcon;
            case "fileUpload":
                return FileUploadIcon;
            case "fileUploadOff":
                return FileUploadOffIcon;
            case "fileUploadOn":
                return FileUploadOnIcon;
            case "foldAll":
                return FoldAllIcon;
            case "folderDownward":
                return FolderDownwardIcon;
            case "folder":
                return FolderIcon;
            case "folderRightward":
                return FolderRightwardIcon;
            case "hamburger":
                return HamburgerIcon;
            case "imageBlock":
                return ImageBlockIcon;
            case "info":
                return InfoIcon;
            case "knob":
                return KnobIcon;
            case "last":
                return LastIcon;
            case "link":
                return LinkIcon;
            case "list":
                return ListIcon;
            case "listview":
                return ListViewIcon;
            case "load":
                return LoadIcon;
            case "logout":
                return LogoutIcon;
            case "memo":
                return MemoIcon;
            case "minus":
                return MinusIcon;
            case "modify":
                return ModifyIcon;
            case "newTabOpen":
                return NewTabOpenIcon;
            case "openAll":
                return OpenAllIcon;
            case "option":
                return OptionIcon;
            case "pdf":
                return PDFIcon;
            case "play":
                return PlayIcon;
            case "plus":
                return PlusIcon;
            case "print":
                return PrintIcon;
            case "publisher":
                return PublisherIcon;
            case "pwHide":
                return PwHideIcon;
            case "pwShow":
                return PwShowIcon;
            case "question":
                return QuestionIcon;
            case "quote":
                return QuoteIcon;
            case "quoteRemove":
                return QuoteRemoveIcon;
            case "quoteAdd":
                return QuoteAddIcon;
            case "refresh":
                return RefreshIcon;
            case "release":
                return ReleaseIcon;
            case "save":
                return SaveIcon;
            case "search":
                return SearchIcon;
            case "setting":
                return SettingIcon;
            case "sideBarReference":
                return SideBarReferenceIcon;
            case "sideBarResearch":
                return SideBarResearchIcon;
            case "sideBarResearchItem":
                return SideBarResearchItemIcon;
            case "sideBarSearch":
                return SideBarSearchIcon;
            case "sidemenuClose":
                return SidemenuCloseIcon;
            case "sidemenuOpen":
                return SidemenuOpenIcon;
            case "star":
                return StarIcon;
            case "stop":
                return StopIcon;
            case "styleBulletList":
                return StyleBulletListIcon;
            case "styleHeader1":
                return StyleHeader1Icon;
            case "styleHeader2":
                return StyleHeader2Icon;
            case "styleHeader3":
                return StyleHeader3Icon;
            case "styleNormalText":
                return StyleNormalTextIcon;
            case "styleTextBgColor":
                return StyleTextBgColorIcon;
            case "styleTextColor":
                return StyleTextColorIcon;
            case "styleToggleList":
                return StyleToggleListIcon;
            case "submenu":
                return SubmenuIcon;
            case "tag":
                return TagIcon;
            case "textBlock":
                return TextBlockIcon;
            case "textStyle":
                return TextStyleIcon;
            case "thread":
                return ThreadIcon;
            case "userFill":
                return UserFillIcon;
            case "user":
                return UserIcon;
            case "warn":
                return WarnIcon;
            case "x":
                return XIcon;
            default:
                return null;
        }
    })();

    if (!IconComponent) return null;

    if (name === "load") {
        return (
            <Components>
                <IconComponent {...commonProps} fill={color} />
            </Components>
        );
    }

    return (
        <IconWrapper color={color} {...rest}>
            <IconComponent {...commonProps} />
        </IconWrapper>
    );
}

export default Icon;
