import { pick } from "lodash";

export const toBibJson = (scienceon_item) => {
    let bib_json = pick(scienceon_item, [
        "entry_type",
        "title",
        "year",
        "author",
        "journal",
        "booktitle",
        "publisher",
        "volume",
        "number",
        "pages",
        "school",
        "keyword",
        "abstract",
        "doi",
        "issn",
        "isbn",
    ]);
    bib_json["citation_key"] = scienceon_item.scienceon_cn;

    return bib_json;
};
