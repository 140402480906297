import { apiUrl } from "utils/urls";

import axios from "axios";

export const postResearch = async (body) => {
    const { data } = await axios.post(apiUrl("research"), body, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return data;
};

export const patchResearch = async (body) => {
    let headers = {};
    if (body.file) {
        headers = {
            "Content-Type": "multipart/form-data",
        };
    }
    const { data } = await axios.patch(apiUrl("research") + "/" + body.id, body, {
        headers: headers,
    });
    return data;
};

export const getResearchList = async (params) => {
    const { data } = await axios.get(apiUrl("research"), { params: params });
    return data;
};

export const getResearch = async (params) => {
    const { data } = await axios.get(apiUrl("research") + "/uuid", {
        params: params,
    });
    return data;
};

export const getResearchReference = async (params) => {
    const { data } = await axios.get(apiUrl("referenceResearch"), {
        params: params,
    });
    return data;
};

export const postReferenceResearch = async (body) => {
    const { data } = await axios.post(apiUrl("referenceResearch") + "/bulk_insert", body);
    return data;
};

export const deleteReferenceResearch = async (body) => {
    const { data } = await axios.delete(apiUrl("referenceResearch") + "/bulk_delete", { data: body });
    return data;
};

export const postResearchAnalysis = async (body) => {
    const { data } = await axios.post(apiUrl("researchAnalysis"), body);
    return data;
};

export const patchResearchAnalysis = async (body) => {
    const { data } = await axios.patch(apiUrl("researchAnalysis") + "/" + body.id, body);
    return data;
};

export const getResearchAnalysis = async (params) => {
    const { data } = await axios.get(apiUrl("researchAnalysis"), {
        params: params,
    });
    return data;
};

export const deleteResearch = async (params) => {
    const { data } = await axios.delete(apiUrl("research") + "/" + params.id);
    return data;
};

export const deleteBulkResearch = async (params) => {
    const { data } = await axios.delete(apiUrl("research") + "/bulk-delete", { data: params });
    return data;
};

export const citationReference = async (body) => {
    const { data } = await axios.post(apiUrl("referenceCitation"), body);
    return data;
};

export const deleteReferenceCitation = async (body) => {
    const { data } = await axios.delete(apiUrl("research") + `/${body.research_uuid}/citation/bulk_delete`, {
        data: body,
    });
    return data;
};

export const deleteReferenceCitationByUuid = async (body) => {
    const { data } = await axios.delete(apiUrl("research") + `/${body.research_uuid}/citation/delete-by-uuid`, {
        data: body,
    });
    return data;
};

export const getResearchMedia = async (params) => {
    const { data } = await axios.get(apiUrl("researchMedia"), {
        params: params,
    });
    return data;
};

export const postResearchMedia = async (body) => {
    const { data } = await axios.post(apiUrl("researchMedia"), body, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return data;
};

export const deleteResearchMedia = async (params) => {
    const { data } = await axios.delete(apiUrl("researchMedia") + "/bulk_delete", { data: params });
    return data;
};

export const postResearchWithHtml = async (body) => {
    const { data } = await axios.post(apiUrl("researchCreateWidthHtml"), body);
    return data;
};
