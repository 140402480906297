import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import Button from "components/atoms/button/Button";
import Td from "components/atoms/table/Td";
import Th from "components/atoms/table/Th";
import GeneralText from "components/atoms/text/GeneralText";

import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import { getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

const BodyContentListView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const ListViewTableWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    box-shadow: ${({ isScrolledLeft, isScrolledRight }) =>
        `${
            isScrolledLeft && isScrolledRight
                ? "inset 8px 0 8px -6px rgba(0, 0, 0, 0.15) , inset -8px 0 8px -6px rgba(0, 0, 0, 0.15)"
                : isScrolledLeft
                ? "inset 8px 0 8px -6px rgba(0, 0, 0, 0.15)"
                : isScrolledRight
                ? "inset -8px 0 8px -6px rgba(0, 0, 0, 0.15)"
                : ""
        }`};
    transition: box-shadow 0.2s ease;
`;

const ListViewTable = styled.table`
    table-layout: fixed;
    border-collapse: collapse;
`;

const TableHead = styled.thead`
    width: 100%;
`;

const BodyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const TableHeadTr = styled.tr`
    th {
        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
`;

const TableBody = styled.tbody`
    > tr {
        height: 48px;
        border-bottom: solid 1px var(--color-Outline);
        cursor: default;

        &:hover {
            transition: all 80ms ease-in;
            background-color: #e9f2f4;
        }
    }
`;

const ListViewPagination = styled.div`
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    padding: 10px 0;
`;

const ListViewPageButtonWrap = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`;

const ListViewPageSelectBoxWrap = styled.div`
    margin-left: auto;
`;

const MAX_PAGE_DISPLAY = 10;

const Table = forwardRef((props, ref) => {
    /**
     * Table Component
     * props:
     * @param tableKey - 테이블 구분키
     * @param data - row data
     * @param columns - column data(header)

     * Optional
     * @param pagination - {pageIndex, pageSize}
     * @param setPagination - 페이지 상태 업데이트 함수

     * @param useSortingColumns - 컬럼 헤더에서 정렬 할지 여부
     * @param sorting - {field:'display_name' ,desc:false}
     * @param setSorting - 정렬 처리 상태 업데이트 함수

     * @param useSizingColumns - 컬럼 너비 조정 여부

     * @param columnVisibility - 컬럼 표시 여부 빈값인 경우 모두 표출
     * @param useDraggableColumns - 컬럼 순서 변경 여부

     * @param useOrderColumns - 컬럼 순서 변경 여부
     */

    const {
        data,
        pagination,
        setPagination,
        sorting,
        setSorting,
        useSizingColumns = true,
        onSizeChange,
        useSortingColumns,
        columnVisibility = [],
        onRowClick,
        useOrderColumns,
        columnOrder = [],
        setColumnOrder,
    } = props;
    const tableRef = React.useRef();

    useImperativeHandle(ref, () => ({
        expandColumn,
    }));

    const getColumnHeaderName = (info) => {
        return (
            <GeneralText size={"small"} fontWeight={"500"} color={"#2F5B51"}>
                {info.column.columnDef.name}
            </GeneralText>
        );
    };

    let columns = props.columns;

    columns = columns.map((column) => {
        if (column.header === undefined) {
            column.header = getColumnHeaderName;
        }
        return column;
    });

    const table = useReactTable({
        data: data,
        columns: columns,
        ...(pagination && {
            manualPagination: true,
            pageCount: Math.ceil(pagination.count / pagination.pageSize),
            initialState: {
                pagination: {
                    pageIndex: pagination.pageIndex - 1,
                    pageSize: pagination.pageSize,
                },
            },
            getPaginationRowModel: getPaginationRowModel(),
            onPaginationChange: (updater) => {
                const updatedPagination =
                    typeof updater === "function" ? updater(table.getState().pagination) : updater;

                setPagination({
                    pageIndex: updatedPagination.pageIndex + 1,
                    pageSize: updatedPagination.pageSize,
                });
            },
        }),
        getCoreRowModel: getCoreRowModel(),
        manualSorting: true,
        ...(setSorting && {
            onSortingChange: (updater) => {
                const updatedSorting = typeof updater === "function" ? updater(table.getState().sorting) : updater;
                setSorting(updatedSorting);
            },
        }),
        getSortedRowModel: sorting ? getSortedRowModel() : undefined,
        columnResizeMode: "onChange",
        columnResizeDirection: "ltr",
        defaultColumn: {
            minSize: 40,
        },
        state: {
            ...(sorting && {
                sorting: sorting,
            }),
            ...(columnVisibility && {
                columnVisibility: columnVisibility,
            }),
            ...(useOrderColumns && {
                columnOrder: columnOrder,
            }),
        },
    });

    useEffect(() => {
        // 페이징 변경 시 반영
        if (pagination) {
            table.setState((old) => {
                return {
                    ...old,
                    pagination: {
                        pageIndex: pagination.pageIndex - 1,
                        pageSize: pagination.pageSize,
                    },
                };
            });
        }
    }, [pagination]);

    useEffect(() => {
        // sorting 테이블에 반영
        if (sorting) {
            table.setState((old) => {
                return {
                    ...old,
                    sorting: sorting,
                };
            });
        } else {
            table.setState((old) => {
                return {
                    ...old,
                    sorting: [],
                };
            });
        }
    }, [sorting]);

    // 페이징 관련 함수
    const currentPage = table.getState().pagination.pageIndex;
    const totalPages = table.getPageCount();

    const getVisiblePages = () => {
        let startPage = Math.max(0, currentPage - Math.floor(MAX_PAGE_DISPLAY / 2));
        let endPage = startPage + MAX_PAGE_DISPLAY - 1;

        if (endPage >= totalPages) {
            endPage = totalPages - 1;
            startPage = Math.max(0, endPage - MAX_PAGE_DISPLAY + 1);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    function handleDragEnd(event) {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const oldIndex = columnOrder.indexOf(active.id);
            const newIndex = columnOrder.indexOf(over.id);
            return setColumnOrder(oldIndex, newIndex);
        }
    }

    const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

    // 테이블 스크롤 너비에 따라 음영 반영
    const tableWrapRef = useRef();
    const [isScrolledLeft, setIsScrolledLeft] = useState(false);
    const [isScrolledRight, setIsScrolledRight] = useState(false);

    useEffect(() => {
        const tableWrap = tableWrapRef.current;

        const handleScroll = () => {
            if (tableWrap) {
                const { scrollLeft, scrollWidth, clientWidth } = tableWrapRef.current;
                setIsScrolledLeft(scrollLeft > 0);
                setIsScrolledRight(scrollLeft + clientWidth < scrollWidth);
            }
        };
        tableWrap?.addEventListener("scroll", handleScroll);
        handleScroll(); // 초기 상태 설정

        return () => {
            tableWrap?.removeEventListener("scroll", handleScroll);
        };
    }, [table?.getTotalSize()]);

    useEffect(() => {
        if (useSizingColumns) {
            const currentSizing = table.getState().columnSizing;
            if (currentSizing) {
                onSizeChange && onSizeChange(currentSizing);
            }
        }
    }, [table.getState().columnSizing]);

    useEffect(() => {
        if (columns) {
            const fitSizeColumns = columns.filter(
                (column) => columnVisibility[column?.id] !== false && column.size === undefined,
            );
            if (fitSizeColumns.length > 0) {
                let currentTableWidth = 0;
                columns.forEach((column) => {
                    if (columnVisibility[column.id] !== false && column.size !== undefined) {
                        currentTableWidth += column.size;
                    }
                });

                const tableWidth = tableRef.current.clientWidth;
                const fitSize = Math.floor(tableWidth - currentTableWidth / fitSizeColumns.length);
                fitSizeColumns.forEach((column) => {
                    column.size = fitSize;
                });
            }
        }
    }, [columns]);

    const expandColumn = () => {
        const tableWidth = tableRef.current.clientWidth; // 테이블 컨테이너의 현재 너비
        const allColumns = table.getAllColumns();

        // 고정된 컬럼과 조정 가능한 컬럼 분리
        const fixedColumns = allColumns.filter((col) => col.columnDef.enableResizing === false);
        const resizableColumns = allColumns.filter(
            (col) => col.getIsVisible() && col.columnDef.enableResizing !== false,
        );

        if (resizableColumns.length === 0) return;

        // 고정된 컬럼의 너비 합산
        const fixedColumnsWidth = fixedColumns.reduce((sum, col) => {
            return sum + (col.getSize() || col.columnDef.minSize || 0);
        }, 0);

        // 남은 너비를 비율에 따라 나눔
        const remainingWidth = tableWidth - fixedColumnsWidth;
        const totalCurrentWidth = resizableColumns.reduce((sum, col) => {
            return sum + (col.getSize() || col.columnDef.minSize || 0);
        }, 0);

        const newColumnSizing = {};
        let accumulatedWidth = 0;
        let lastResizableColumnId = null;

        resizableColumns.forEach((col, index) => {
            const currentSize = col.getSize() || col.columnDef.minSize || 0;
            const ratio = currentSize / totalCurrentWidth;
            const newSize = Math.floor(remainingWidth * ratio);

            newColumnSizing[col.id] = newSize;
            accumulatedWidth += newSize;

            if (index === resizableColumns.length - 1) {
                lastResizableColumnId = col.id;
            }
        });

        // 소수점 오차를 마지막 컬럼에 추가
        const extraWidth = remainingWidth - accumulatedWidth;
        if (lastResizableColumnId && extraWidth > 0) {
            newColumnSizing[lastResizableColumnId] += extraWidth;
        }

        // 새로운 컬럼 사이즈 적용
        table.setColumnSizing(newColumnSizing);
    };

    return (
        <BodyContent>
            <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToHorizontalAxis]}
                onDragEnd={handleDragEnd}
                sensors={sensors}
            >
                <BodyContentListView ref={tableRef}>
                    <ListViewTableWrap
                        ref={tableWrapRef}
                        isScrolledLeft={isScrolledLeft}
                        isScrolledRight={isScrolledRight}
                    >
                        <ListViewTable
                            style={{
                                width: table.getTotalSize(),
                            }}
                        >
                            <TableHead>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableHeadTr key={headerGroup.id}>
                                        <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                                            {headerGroup.headers.map((header) => (
                                                <Th
                                                    key={header.id}
                                                    header={header}
                                                    useSortingColumns={useSortingColumns}
                                                    useOrderColumns={useOrderColumns}
                                                    useSizingColumns={useSizingColumns}
                                                />
                                            ))}
                                        </SortableContext>
                                    </TableHeadTr>
                                ))}
                            </TableHead>

                            <TableBody>
                                {table.getRowModel().rows.map((row) => (
                                    <tr
                                        key={row.id}
                                        onClick={() => onRowClick && onRowClick(row)}
                                        style={{ cursor: onRowClick ? "pointer" : "default" }}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <SortableContext
                                                key={cell.id}
                                                items={columnOrder}
                                                strategy={horizontalListSortingStrategy}
                                            >
                                                <Td key={cell.id} cell={cell} />
                                            </SortableContext>
                                        ))}
                                    </tr>
                                ))}
                            </TableBody>
                        </ListViewTable>
                    </ListViewTableWrap>

                    {/* 페이징 영역 시작 */}
                    {pagination && (
                        <ListViewPagination>
                            <ListViewPageButtonWrap>
                                <Button
                                    onlyIcon
                                    transparentDisabled
                                    bgColor={"transparent"}
                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                    onClick={() => table.firstPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <Icon
                                        name={"arrowLeftDouble"}
                                        color={
                                            !table.getCanPreviousPage()
                                                ? "var(--color-DisabledText)"
                                                : "var(--color-SubBlack)"
                                        }
                                    />
                                </Button>
                                <Button
                                    onlyIcon
                                    transparentDisabled
                                    margin={"0 4px"}
                                    bgColor={"transparent"}
                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                    onClick={() => table.previousPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <Icon
                                        name={"arrowLeft"}
                                        color={
                                            !table.getCanPreviousPage()
                                                ? "var(--color-DisabledText)"
                                                : "var(--color-SubBlack)"
                                        }
                                    />
                                </Button>
                                {getVisiblePages().map((pageIndex) => (
                                    <Button
                                        onlyText
                                        margin={"0 4px"}
                                        height={"24px"}
                                        width={"auto"}
                                        minWidth={"24px"}
                                        key={pageIndex}
                                        onClick={() => table.setPageIndex(pageIndex)}
                                        bgColor={currentPage === pageIndex ? "var(--color-Key)" : "transparent"}
                                        hoverBgColor={
                                            currentPage === pageIndex
                                                ? "var(--color-DarkKey)"
                                                : "var(--color-ButtonHover4)"
                                        }
                                        buttonText={pageIndex + 1}
                                        fontColor={
                                            currentPage === pageIndex ? "var(--color-White)" : "var(--color-SubBlack)"
                                        }
                                    />
                                ))}
                                <Button
                                    onlyIcon
                                    transparentDisabled
                                    margin={"0 4px"}
                                    bgColor={"transparent"}
                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                    onClick={() => table.nextPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <Icon
                                        name={"arrowRight"}
                                        color={
                                            !table.getCanNextPage()
                                                ? "var(--color-DisabledText)"
                                                : "var(--color-SubBlack)"
                                        }
                                    />
                                </Button>
                                <Button
                                    onlyIcon
                                    transparentDisabled
                                    bgColor={"transparent"}
                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                    onClick={() => table.lastPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <Icon
                                        name={"arrowRightDouble"}
                                        color={
                                            !table.getCanNextPage()
                                                ? "var(--color-DisabledText)"
                                                : "var(--color-SubBlack)"
                                        }
                                    />
                                </Button>
                            </ListViewPageButtonWrap>
                            <ListViewPageSelectBoxWrap>
                                <SelectBox
                                    width={"120px"}
                                    optionWidth={"100%"}
                                    topBottom={"bottom"}
                                    reverse={"180deg"}
                                    value={String(table.getState().pagination.pageSize)}
                                    items={[
                                        { id: "10", name: "10개씩 보기" },
                                        { id: "20", name: "20개씩 보기" },
                                        { id: "30", name: "30개씩 보기" },
                                        { id: "40", name: "40개씩 보기" },
                                        { id: "50", name: "50개씩 보기" },
                                    ]}
                                    onChange={(id) => {
                                        table.setPageSize(Number(id));
                                    }}
                                />
                            </ListViewPageSelectBoxWrap>
                        </ListViewPagination>
                    )}
                </BodyContentListView>
            </DndContext>
        </BodyContent>
    );
});

export default Table;
