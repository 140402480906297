import React, { useEffect, useRef, useState } from "react";
import { useIsFetching, useIsMutating } from "react-query";
import { Link, useSearchParams } from "react-router-dom";

import styled, { css } from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import useToast from "components/atoms/toast/useToast";

import Table from "components/molecules/table/Table";
import {
    getColumnOrder,
    getColumnSize,
    getColumnVisibility,
    getDefaultConfig,
    updateColumnOrder,
} from "components/molecules/table/TableManager";
import SummarySidebar from "components/organisms/SummarySidebar";
import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceCitationMultiAddModal from "components/page/modal/ReferenceCitationMultiAddModal";
import LoginUserLayout from "components/templates/LoginUserLayout";

import { useBibliographyCreate } from "hooks/queries/useBibliographies";
import { useDeletePaperSearchHistory, usePaperSearch, usePaperSearchHistory } from "hooks/queries/usePapers";
import { useUser } from "hooks/queries/useUser";

import { useDevice } from "utils/device";
import { toBibJson } from "utils/scienceon";

const WidthLimit = styled.div`
    display: flex;
    flex-direction: ${(props) => props.flexDirection};
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
    margin: 0 auto;
    padding: 0 48px;
    width: 100%;
    height: ${(props) => props.height};
`;

const Header = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    height: 48px;
    max-height: 48px;
    align-items: center;
    background-color: var(--color-White);
`;

const PageTitle = styled.h1`
    position: relative;
    top: 2px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-Black);
`;

const Body = styled.div`
    display: flex;
    justify-content: center;
    margin: 48px 0 0 0;
    width: 100%;
    height: calc(100vh - 104px);
    max-height: calc(100vh - 104px);
    overflow: auto;
`;

const SearchInputWrap = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 0 16px;
    flex: 1;
    height: 100%;
`;

const SearchMaxWidth = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

const RefPoint = styled.div`
    position: relative;
    flex: 1;
    min-width: 100%;
    height: 34px;
`;

const InputWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: auto;
    background-color: var(--color-White);
    border-radius: 8px;
    border: solid 1px var(--color-Line);
    transition: all 120ms ease-in;
    z-index: 4;

    &:focus-within {
        border: solid 1px var(--color-Key);
    }
`;

const RowWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
`;

const SearchInput = styled.input`
    padding: 12px 4px;
    width: calc(100% - 10px);
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    background-color: var(--color-White);
    color: var(--color-Black);
    border: transparent;
    outline: none;

    &::placeholder {
        color: var(--color-Grey2);
    }
`;

const SearchButtonWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SearchHistoryList = styled.div`
    flex-direction: column;
    margin: 0 8px;
    padding: 8px 0 4px 0;
    width: calc(100% - 16px);
    background-color: var(--color-White);
    border-top: solid 1px var(--color-Grey3);
`;

const SearchHistoryItem = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 0;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-Black);
    border: transparent;
    background-color: var(--color-White);

    &:hover {
        > div {
            background-color: var(--color-ButtonHover4);
        }
    }
`;

const ItemInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    height: 100%;
    background-color: var(--color-White);
    border-radius: 4px;
    border: transparent;

    &:first-child {
        margin: 4px 0 0 0;
    }

    &:last-child {
        margin: 0 0 4px 0;
    }
`;

const OptionBoxContainer = styled.div`
    position: absolute;
    top: 28px;
    right: 0;
    display: ${(props) => (props.visible ? "block" : "none")};
    padding: 8px;
    width: ${(props) => props.width};
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    z-index: 3;
`;

const CheckOptionWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    max-height: 200px;
`;

const CheckItemWrap = styled.div``;

const CheckRadioItemLabel = styled.label`
    display: flex;
    align-items: center;
    height: 24px;
`;

const InnerLeft = styled.div`
    display: flex;
    align-items: center;
`;

const SubHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    flex-shrink: 0;
`;

const HeaderLeftSection = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

const SelectBoxSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 8px;
`;

const BlankView = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const DOIWrap = styled.div`
    display: flex;
    align-items: center;

    a {
        margin: 4px 4px 0 0;

        &:last-child {
            margin: 4px 0px 0 0;
        }
    }
`;

const OptionButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
    background-color: ${(props) => (props.optionBoxActive ? "var(--color-White)" : "var(--color-Button4)")};
    border: solid 1.5px ${(props) => (props.optionBoxActive ? "var(--color-Button2)" : "transparent")};
    border-radius: 4px;
    outline: none;

    &:hover {
        background-color: ${(props) => (props.optionBoxActive ? "" : "var(--color-ButtonHover4)")};
    }
`;

const LinkButtonStyle = {
    height: "18px",
    padding: "0px 4px",
    borderRadius: "4px",
    bgColor: "var(--color-Button3)",
    hoverBgColor: "var(--color-ButtonHover3)",
    fontSize: "xsmall",
};

const AddInfoSection = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;
`;

const AddInfoBox = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    background-color: var(--color-InfoBox);

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 24px;
        `};
`;

const AddInfoItem = styled.div`
    width: 50%;
    max-width: calc(50% - 8px);
`;

const AddInfoItemRow = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 8px 6px;
    width: 100%;

    &:last-child {
        margin: 0 0 0 6px;
    }
`;

const TextSection = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DivideLine = styled.span`
    display: block;
    margin: 0 12px;
    width: 1px;
    height: 16px;
    background-color: var(--color-Outline);
`;

const CheckIconStyle = {
    name: "check",
    size: "14",
    color: "var(--color-DarkKey)",
};

const ItemTextStyle = {
    margin: "0 0 0 8px",
    size: "large",
    color: "var(--color-SubBlack)",
};

function SearchPage(props) {
    const device = useDevice();

    const [searchParams] = useSearchParams();

    const [searchInput, setSearchInput] = useState(searchParams.get("q") || "");
    const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");

    const [user, setUser] = useState({});
    const [paperList, setPaperList] = useState({
        results: [],
        count: 0,
    });

    const [displaySearchHistory, setDisplaySearchHistory] = useState("none");
    const [searchHistory, setSearchHistory] = useState(JSON.parse(localStorage.getItem("searchHistory")) || []);

    const [optionBoxActive, setOptionBoxActive] = useState(false);
    const [sorting, setSorting] = useState("NULL");
    const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 1, count: 0 });

    const [showSummarySidebar, setShowSummarySidebar] = useState(false);

    const [language, setLanguage] = useState("");

    const [researchSelectModal, setResearchSelectModal] = useState(false);

    const userQuery = useUser();
    const paperSearchQuery = usePaperSearch({
        "search-query": `${searchQuery}`,
        sort: sorting,
        page_size: pagination.pageSize,
        page_index: pagination.pageIndex,
    });
    const paperCreateMutation = useBibliographyCreate();

    const paperSearchHistoryQuery = usePaperSearchHistory();
    const paperSearchHistoryDeleteMutation = useDeletePaperSearchHistory();

    const searchInputRef = useRef(null);
    const optionBoxRef = useRef(null);

    const { setToast } = useToast();

    const { setLoading } = useLoading();
    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    useEffect(() => {
        if (sorting) {
            setPagination({ pageIndex: 1, pageSize: pagination.pageSize, count: pagination.count });
        }
    }, [sorting]);

    useEffect(() => {
        if (pagination) {
            paperSearchQuery.refetch();
        }
    }, [pagination]);

    // 초기화
    useEffect(() => {
        return () => {
            paperSearchHistoryQuery.remove();
        };
    }, []);

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
        if (searchQuery) {
            let tempSearchHistory = searchHistory;
            if (searchHistory.includes(searchInput)) {
                // 검색어를 포함하고 있다면 제거
                tempSearchHistory = searchHistory.filter((item) => item !== searchInput);
            }
            tempSearchHistory.unshift(searchInput);
            if (tempSearchHistory.length > 10) {
                // 길이가 10개를 넘어가면 마지막 요소를 제거
                tempSearchHistory.pop();
            }
            setSearchHistory(tempSearchHistory);
        }
        paperSearchQuery.refetch({ page_index: 1, page_size: pagination.pageSize });
    }, [searchQuery]);

    useEffect(() => {
        if (paperSearchHistoryQuery.data) {
            if (user.email) {
                setSearchHistory(paperSearchHistoryQuery.data);
            }
        }
    }, [paperSearchHistoryQuery.data, user]);

    useEffect(() => {
        localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
    }, [searchHistory.length]);

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
            if (language === "") {
                setLanguage(userQuery.data.env_ai_language);
            }
        }
        if (paperSearchQuery.data) {
            setPaperList(paperSearchQuery.data);
            if (pagination.count !== paperSearchQuery.data.count) {
                setPagination({
                    ...pagination,
                    count: paperSearchQuery.data.count,
                });
            }
        }
    }, [userQuery.data, paperSearchQuery.data]);

    const rerenderTable = React.useReducer(() => ({}), {})[1];

    const checkedBibsRef = useRef([]);

    const handleCheckboxAllChange = (e) => {
        // 전체 선택이면 모든 id를, 그렇지 않으면 빈 배열을 반환
        checkedBibsRef.current = e.target.checked ? paperList?.results?.map((obj) => obj.scienceon_cn) : [];
        rerenderTable();
    };

    const handleCheckboxChange = (event) => {
        let scienceon_cn = event.target.id;
        const index = checkedBibsRef.current.indexOf(scienceon_cn);
        if (index > -1) {
            checkedBibsRef.current = checkedBibsRef.current.filter((item) => item !== scienceon_cn);
        } else {
            checkedBibsRef.current = [...checkedBibsRef.current, scienceon_cn];
        }
        rerenderTable();
    };

    const handleQuoteButtonClicked = (obj) => {
        let bib_json = toBibJson(obj);
        const link = {};
        link.google = "https://scholar.google.com/scholar?q=" + encodeURIComponent(obj?.title);
        if (obj.doi) link.doi = obj.doi;
        if (obj.scienceon_content_url) link.scienceon_content_url = obj.scienceon_content_url;
        if (obj.scienceon_fulltext_url) link.scienceon_fulltext_url = obj.scienceon_fulltext_url;

        paperCreateMutation.mutate(
            {
                body: {
                    bib_json: bib_json,
                    display_name: obj.title,
                    link: link,
                },
            },
            {
                onSuccess: (data, variables, context) => {
                    setToast("참고문헌을 등록했습니다.", "info");
                },
            },
        );
    };

    const columns = React.useMemo(
        () => [
            {
                id: "scienceon_cn",
                accessorKey: "scienceon_cn",
                header: null,
                name: "선택",
                size: 45,
                minSize: 45,
                enableResizing: false,
                content: (info) => {
                    const value = info.getValue();
                    return (
                        <Checkbox
                            id={value}
                            checked={checkedBibsRef.current?.includes(value)}
                            onChange={handleCheckboxChange}
                        />
                    );
                },
            },
            {
                id: "title",
                accessorKey: "title",
                name: "논문명",
                style: { justifyContent: "left" },
                text: (info) => {
                    return info.getValue();
                },
                content: (info) => {
                    return (
                        <DOIWrap>
                            {info.row.original?.doi && (
                                <Link to={info.row.original.doi} target={"_blank"}>
                                    <Button onlyText {...LinkButtonStyle} buttonText={"DOI"} />
                                </Link>
                            )}
                            <Link
                                to={
                                    "https://scholar.google.com/scholar?q=" +
                                    encodeURIComponent(info.row.original?.title)
                                }
                                target={"_blank"}
                            >
                                <Button onlyText {...LinkButtonStyle} buttonText={"Google"} />
                            </Link>
                            {info.row.original?.scienceon_content_url && (
                                <Link to={info.row.original.scienceon_content_url} target={"_blank"}>
                                    <Button onlyText {...LinkButtonStyle} buttonText={"ScienceON"} />
                                </Link>
                            )}
                            {info.row.original?.scienceon_fulltext_url && (
                                <Link to={info.row.original.scienceon_fulltext_url} target={"_blank"}>
                                    <Button onlyText {...LinkButtonStyle} buttonText={"PDF"} />
                                </Link>
                            )}
                        </DOIWrap>
                    );
                },
            },
            {
                id: "year",
                accessorKey: "year",
                name: "발행년도",
                size: 60,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "publisher",
                accessorKey: "publisher",
                name: "발행처",
                size: 100,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "author",
                accessorKey: "author",
                name: "저자",
                size: 80,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "keyword",
                accessorKey: "keyword",
                name: "키워드",
                size: 100,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "abstract",
                accessorKey: "abstract",
                name: "초록",
                size: 100,
                text: (info) => {
                    return info.getValue();
                },
            },
            {
                id: "add",
                accessorKey: "add",
                name: "추가",
                size: 60,
                minSize: 60,
                maxSize: 60,
                enableResizing: false,
                content: (info) => {
                    return (
                        <Button
                            onlyText
                            size={"xsmall"}
                            width={"40px"}
                            height={"24px"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            buttonText={"추가"}
                            onClick={() => handleQuoteButtonClicked(info.row.original)}
                        />
                    );
                },
            },
        ],
        [],
    );

    useEffect(() => {
        // 목록이 변경되면 checkedBibsRef를 초기화 한다.
        checkedBibsRef.current = [];
        rerenderTable();
    }, [paperList.results]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (optionBoxRef.current && !optionBoxRef.current.contains(e.target)) {
                setOptionBoxActive(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionBoxRef]);

    const PAGE_KEY = "search-paper";

    const [tableConfig, setTableConfig] = useState(
        localStorage.getItem(PAGE_KEY) ? JSON.parse(localStorage.getItem(PAGE_KEY)).paper : getDefaultConfig(columns),
    );

    useEffect(() => {
        const initialConfig = getColumnSize(columns, tableConfig);
        // columns 업데이트
        columns.forEach((column) => {
            const config = initialConfig.find((item) => item.id === column.id);
            if (config) {
                column.size = config.size;
            }
        });
    }, []);

    useEffect(() => {
        localStorage.setItem(
            PAGE_KEY,
            JSON.stringify({
                paper: tableConfig,
            }),
        );
    }, [tableConfig]);

    const tableRef = useRef();

    return (
        <LoginUserLayout>
            <HtmlHead title={"논문 DB 검색"} />
            <Header>
                <WidthLimit device={device} justifyContent={"space-between"} alignItems={"center"} height={"48px"}>
                    <PageTitle>논문 DB 검색</PageTitle>
                    <SearchInputWrap>
                        <SearchMaxWidth device={device}>
                            <RefPoint>
                                <InputWrap>
                                    <RowWrap>
                                        <SearchInput
                                            ref={searchInputRef}
                                            placeholder="검색어를 입력해주세요"
                                            value={searchInput}
                                            onChange={(e) => {
                                                // 수정금지: 랜딩페이지에서 전달되는 쿼리 처리를 위해 searchQuery 와 searchInput 모두 필요
                                                setSearchInput(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                                                    if (searchInput) {
                                                        setSearchQuery(searchInput);
                                                    }
                                                }
                                            }}
                                            onFocus={() => {
                                                setDisplaySearchHistory("flex");
                                            }}
                                            onBlur={() => {
                                                setDisplaySearchHistory("none");
                                            }}
                                        />
                                        <SearchButtonWrap>
                                            {searchInput && (
                                                <Tooltip message={"초기화"}>
                                                    <Button
                                                        onlyIcon
                                                        bgColor={"transparent"}
                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                        onClick={(e) => {
                                                            setSearchInput("");
                                                            setSearchQuery("");
                                                        }}
                                                    >
                                                        <Icon name={"x"} color={"var(--color-SubBlack)"} />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            <Tooltip message={"검색"}>
                                                <Button
                                                    onlyIcon
                                                    bgColor={"transparent"}
                                                    hoverBgColor={"var(--color-ButtonHover4)"}
                                                    onClick={(e) => setSearchQuery(searchInput)}
                                                >
                                                    <Icon name={"search"} size={"12"} color={"var(--color-SubBlack)"} />
                                                </Button>
                                            </Tooltip>
                                        </SearchButtonWrap>
                                    </RowWrap>
                                    {searchHistory.length > 0 && (
                                        <SearchHistoryList style={{ display: displaySearchHistory }}>
                                            {searchHistory.map((item, index) => (
                                                <SearchHistoryItem
                                                    key={index}
                                                    onMouseDown={(e) => {
                                                        setSearchInput(item);
                                                        setSearchQuery(item);
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    <ItemInner>
                                                        <InnerLeft>
                                                            <Icon
                                                                name={"last"}
                                                                size={"14"}
                                                                color={"var(--color-SubBlack)"}
                                                            />
                                                            <GeneralText
                                                                size={"large"}
                                                                color={"var(--color-Black)"}
                                                                margin={"0 0 0 12px"}
                                                            >
                                                                {item}
                                                            </GeneralText>
                                                        </InnerLeft>
                                                        <Tooltip message={"삭제"}>
                                                            <Button
                                                                onlyIcon
                                                                bgColor={"transparent"}
                                                                hoverBgColor={"var(--color-ButtonHover5)"}
                                                                onMouseDown={(e) => {
                                                                    setSearchHistory(
                                                                        searchHistory.filter((_, i) => i !== index),
                                                                    );
                                                                    paperSearchHistoryDeleteMutation.mutate({
                                                                        query: item,
                                                                    });
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <Icon
                                                                    name={"delete"}
                                                                    size={"12"}
                                                                    color={"var(--color-SubBlack)"}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    </ItemInner>
                                                </SearchHistoryItem>
                                            ))}
                                        </SearchHistoryList>
                                    )}
                                </InputWrap>
                            </RefPoint>
                        </SearchMaxWidth>
                    </SearchInputWrap>
                </WidthLimit>
            </Header>

            <Body>
                <WidthLimit device={device} flexDirection={"column"} height={"calc(100vh-104px)"}>
                    {paperList && paperList?.results?.length > 0 && (
                        <SubHeader>
                            <HeaderLeftSection>
                                <Checkbox
                                    id={"all"}
                                    indeterminate={
                                        checkedBibsRef.current.length > 0 &&
                                        checkedBibsRef.current.length < paperList?.results?.length
                                    }
                                    checked={checkedBibsRef.current.length === paperList?.results?.length}
                                    onChange={handleCheckboxAllChange}
                                    margin={"0 8px 0 0"}
                                />
                                <Tooltip message={"최대 10개의 선택한 논문을 요약합니다."}>
                                    <Button
                                        onlyText
                                        height={"24px"}
                                        width={"70px"}
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        buttonText={"논문 요약"}
                                        disabled={checkedBibsRef.current.length === 0}
                                        onClick={() => {
                                            if (checkedBibsRef.current.length > 10) {
                                                Alert("warn", "에러", "요약은 10개까지만 가능합니다.");
                                                return;
                                            }
                                            setShowSummarySidebar(true);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip message={"선택한 논문을 내 참고문헌으로 추가하고, 리서치에 연결합니다."}>
                                    <Button
                                        onlyText
                                        height={"24px"}
                                        width={"70px"}
                                        bgColor={"var(--color-Button2)"}
                                        hoverBgColor={"var(--color-ButtonHover2)"}
                                        buttonText={"리서치 연결"}
                                        margin={"0 0 0 8px"}
                                        disabled={checkedBibsRef.current.length === 0}
                                        onClick={() => {
                                            setResearchSelectModal(true);
                                        }}
                                    />
                                </Tooltip>
                                {checkedBibsRef.current.length > 0 && (
                                    <GeneralText margin={"0 0 0 10px"} size={"small"} fontWeight={"300"}>
                                        {checkedBibsRef.current.length}개의 항목이 선택됨
                                    </GeneralText>
                                )}
                            </HeaderLeftSection>
                            <SelectBoxSection>
                                <a href={"https://scienceon.kisti.re.kr/"}>
                                    <GeneralText margin={"0 10px 0 0"} size={"small"} fontWeight={"300"}>
                                        Powered by ScienceON
                                    </GeneralText>
                                </a>

                                <SelectBox
                                    width="120px"
                                    height={"28px"}
                                    maxWidth="120px"
                                    optionWidth="100%"
                                    optionMinWidth="120px"
                                    topBottom="top"
                                    reverse="180deg"
                                    value={sorting}
                                    items={[
                                        { id: "NULL", name: "정확도" },
                                        { id: "pubyear", name: "발행일순" },
                                    ]}
                                    onChange={(value) => setSorting(value)}
                                />
                                <DivideLine />
                                <Tooltip message={"테이블 항목"}>
                                    <OptionButton
                                        optionBoxActive={optionBoxActive}
                                        onClick={() => {
                                            setOptionBoxActive(!optionBoxActive);
                                        }}
                                        ref={optionBoxRef}
                                    >
                                        <Icon
                                            name={"option"}
                                            color={optionBoxActive ? "var(--color-DarkKey)" : "var(--color-Black)"}
                                            size={"14px"}
                                        />
                                        <OptionBoxContainer width={"240px"} visible={optionBoxActive}>
                                            <GeneralText
                                                margin={"0 0 16px 0"}
                                                size={"regular"}
                                                fontWeight={"600"}
                                                textAlign={"left"}
                                            >
                                                테이블 항목
                                            </GeneralText>
                                            <CheckOptionWrap onClick={(e) => e.stopPropagation()}>
                                                {columns.map((column) => {
                                                    return ["title", "scienceon_cn", "add"].includes(
                                                        column.id,
                                                    ) ? null : (
                                                        <CheckItemWrap key={column.id}>
                                                            <CheckRadioItemLabel>
                                                                <input
                                                                    {...{
                                                                        type: "checkbox",
                                                                        style: { display: "block" },
                                                                        checked: tableConfig.find(
                                                                            (item) => item.id === column.id,
                                                                        )
                                                                            ? tableConfig.find(
                                                                                  (item) => item.id === column.id,
                                                                              ).visibility
                                                                            : true,
                                                                        onChange: (e) => {
                                                                            setTableConfig((prev) =>
                                                                                prev.map((item) =>
                                                                                    item.id === column.id
                                                                                        ? {
                                                                                              ...item,
                                                                                              visibility:
                                                                                                  e.target.checked,
                                                                                          }
                                                                                        : item,
                                                                                ),
                                                                            );
                                                                            e.stopPropagation();
                                                                        },
                                                                    }}
                                                                />{" "}
                                                                <GeneralText margin={"0 0 0 4px"} size={"small"}>
                                                                    {column.name}
                                                                </GeneralText>
                                                            </CheckRadioItemLabel>
                                                        </CheckItemWrap>
                                                    );
                                                })}
                                            </CheckOptionWrap>
                                        </OptionBoxContainer>
                                    </OptionButton>
                                </Tooltip>
                                <Tooltip message={"테이블 너비를 현재 화면 크기에 맞게 조정합니다"}>
                                    <Button
                                        onlyIcon
                                        transparentDisabled
                                        bgColor={"var(--color-Button4)"}
                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                        onClick={() => {
                                            tableRef.current.expandColumn();
                                        }}
                                    >
                                        <Icon name={"expand"} size={"14"} color={"var(--color-Black)"} />
                                    </Button>
                                </Tooltip>
                            </SelectBoxSection>
                        </SubHeader>
                    )}
                    {paperList && paperList?.results?.length > 0 ? (
                        <Table
                            ref={tableRef}
                            data={paperList?.results || []}
                            columns={columns}
                            useSizingColumns={true}
                            useSortingColumns={false}
                            useOrderColumns={true}
                            pagination={pagination}
                            setPagination={(page) => {
                                if (page.pageSize !== pagination.pageSize) {
                                    setPagination({
                                        pageIndex: 1,
                                        pageSize: page.pageSize,
                                        count: page.count,
                                    });
                                } else {
                                    setPagination({
                                        ...page,
                                        count: pagination.count,
                                    });
                                }
                            }}
                            onSizeChange={(changedColumn) => {
                                Object.keys(changedColumn).forEach((key) => {
                                    setTableConfig((prev) =>
                                        prev.map((item) =>
                                            item.id === key ? { ...item, size: changedColumn[key] } : item,
                                        ),
                                    );
                                });
                            }}
                            columnVisibility={getColumnVisibility(tableConfig)}
                            columnOrder={getColumnOrder(tableConfig)}
                            setColumnOrder={(oldIndex, newIndex) =>
                                setTableConfig(updateColumnOrder(tableConfig, oldIndex, newIndex))
                            }
                        />
                    ) : (
                        <BlankView>
                            <TextSection>
                                <GeneralText size={"large"}>검색 결과가 없습니다.</GeneralText>
                            </TextSection>
                            <AddInfoSection>
                                <AddInfoBox device={device}>
                                    <AddInfoItem>
                                        <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                            키워드를 입력하여 관련 논문을 찾아볼 수 있습니다.
                                        </GeneralText>
                                        <AddInfoItemRow>
                                            <Icon {...CheckIconStyle} />
                                            <GeneralText {...ItemTextStyle}>
                                                최신 연구 자료를 검색할 수 있습니다.
                                            </GeneralText>
                                        </AddInfoItemRow>
                                        <AddInfoItemRow>
                                            <Icon {...CheckIconStyle} />
                                            <GeneralText {...ItemTextStyle}>
                                                검색 결과는 정확도 순, 최신 순으로 정렬할 수 있습니다.
                                            </GeneralText>
                                        </AddInfoItemRow>
                                    </AddInfoItem>
                                    <AddInfoItem>
                                        <GeneralText margin={"0 0 16px 0"} size={"large"} fontWeight={"600"}>
                                            검색된 논문들을 요약하거나 내 참고문헌으로 저장할 수 있습니다.
                                        </GeneralText>
                                        <AddInfoItemRow>
                                            <Icon {...CheckIconStyle} />
                                            <GeneralText {...ItemTextStyle}>AI가 논문들을 요약해드립니다.</GeneralText>
                                        </AddInfoItemRow>
                                        <AddInfoItemRow>
                                            <Icon {...CheckIconStyle} />
                                            <GeneralText {...ItemTextStyle}>
                                                관심 있는 논문을 내 참고문헌으로 추가할 수 있습니다.
                                            </GeneralText>
                                        </AddInfoItemRow>
                                    </AddInfoItem>
                                </AddInfoBox>
                            </AddInfoSection>
                        </BlankView>
                    )}
                </WidthLimit>
            </Body>
            {showSummarySidebar && (
                <SummarySidebar
                    papers={{
                        type: "searchPapers",
                        list: checkedBibsRef.current.map((scienceon_cn) => {
                            return paperList.results.find((p) => p.scienceon_cn === scienceon_cn);
                        }),
                    }}
                    exit={() => setShowSummarySidebar(false)}
                />
            )}
            {
                /* 리서치 연결 모달 */
                researchSelectModal && (
                    <AbstractModal modalTitle="리서치 연결" width={480} exitModal={() => setResearchSelectModal(false)}>
                        <ReferenceCitationMultiAddModal
                            exitModal={() => setResearchSelectModal(false)}
                            referenceBibJsons={checkedBibsRef.current.map((scienceon_cn) => {
                                const paper = paperList.results.find((p) => p.scienceon_cn === scienceon_cn);
                                return toBibJson(paper);
                            })}
                        />
                    </AbstractModal>
                )
            }
        </LoginUserLayout>
    );
}

export default SearchPage;
