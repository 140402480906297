export const getDefaultConfig = (columns) => {
    const config = [];

    columns.forEach((column, index) => {
        config.push({
            id: column.id,
            order: index,
            visibility: column.defaultVisibility !== false,
            size: column.size,
        });
    });
    return config;
};

export const getColumnVisibility = (config) => {
    const visibility = {};

    config.forEach((column) => {
        visibility[column.id] = column.visibility;
    });

    return visibility;
};

export const getColumnOrder = (config) => {
    const order = [];

    config.forEach((column) => {
        order[column.order] = column.id;
    });
    return order;
};

export const updateColumnOrder = (config, oldIndex, newIndex) => {
    const newConfig = [...config];
    const [removed] = newConfig.splice(oldIndex, 1);

    newConfig.splice(newIndex, 0, removed);

    return newConfig.map((column, index) => {
        return {
            ...column,
            order: index,
        };
    });
};

export const getColumnSize = (columns, config) => {
    if (config) {
        const newConfig = [...config];

        const storedSizes = newConfig.reduce((acc, column) => {
            acc[column.id] = column.size;
            return acc;
        }, {});
        return columns.map((column) => ({
            ...column,
            size: storedSizes[column.id] || column.size,
        }));
    }
    return columns;
};
