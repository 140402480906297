import React from "react";

import styled from "styled-components";

import Tooltip from "components/atoms/Tooltip";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Container = styled.div`
    margin: ${(props) => props.margin};
    width: ${(props) => props.width || "100%"};
`;

const LabelSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 4px 0;
`;

const LabelWrap = styled.div`
    display: flex;
`;

const InfoRow = styled.div`
    display: flex;
    align-items: center;
`;

const TooltipButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: transparent;
    background-color: var(--color-SubBlack);
    cursor: pointer;
`;

const QuestionText = styled.p`
    position: relative;
    top: 6.1px;
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--color-White);
`;

const InputContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: ${(props) => props.bgColor};
`;

const InputBox = styled.input`
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    background-color: transparent;
    border: 0;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: var(--color-Grey2);
    }
`;

function InputWithLabel(props, ref) {
    const {
        width,
        margin,
        labelText,
        essenMarkDp,
        infoText,
        id,
        tooltipMessage,
        bgColor,
        placeholder,
        type,
        maxLength,
        value,
        onChange,
        onKeyDown,
        style,
        accept,
        disabled,
        name,
    } = props;

    return (
        <Container width={width} margin={margin} style={style}>
            <LabelSection>
                <LabelWrap>
                    <TitleText size={"subSmall"} color={"var(--color-SubBlack)"}>
                        {labelText}
                    </TitleText>
                    <TitleText
                        size={"regular"}
                        display={essenMarkDp ? "inline-block" : "none"}
                        margin={"0 0 0 2px"}
                        color={"var(--color-Key)"}
                    >
                        *
                    </TitleText>
                </LabelWrap>
                <InfoRow>
                    <GeneralText size={"small"} margin={"0 4px 0 0"} color={"var(--color-SubBlack)"}>
                        {infoText}
                    </GeneralText>
                    {tooltipMessage && (
                        <Tooltip message={tooltipMessage}>
                            <TooltipButton>
                                <QuestionText>?</QuestionText>
                            </TooltipButton>
                        </Tooltip>
                    )}
                </InfoRow>
            </LabelSection>
            <InputContainer bgColor={bgColor}>
                <InputBox
                    id={id}
                    placeholder={placeholder}
                    type={type}
                    maxLength={maxLength}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    ref={ref}
                    accept={accept}
                    name={name}
                    disabled={disabled}
                />
            </InputContainer>
        </Container>
    );
}

export default React.forwardRef(InputWithLabel);
