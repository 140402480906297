import React, { useEffect, useRef, useState } from "react";
import "react-complex-tree/lib/style-modern.css";
import SortableList, { SortableItem } from "react-easy-sort";
import { useLocation, useNavigate } from "react-router-dom";

import styled, { css } from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import ReferenceTagTreeView from "components/organisms/reference/ReferenceTagTreeView";
import AlertBanner from "components/templates/banner/AlertBanner";
import PageHeader from "components/templates/header/PageHeader";

import { useTags } from "hooks/queries/useBibliographies";
import { useResearchNameList } from "hooks/queries/useResearch";
import { useUser } from "hooks/queries/useUser";

import Constants from "utils/constants";
import { useDevice } from "utils/device";
import { apiUrl } from "utils/urls";

import { arrayMoveImmutable } from "array-move";
import axios from "axios";

const SIDE_WIDTH_MIN = 20;
const SIDE_WIDTH_MIN_PX = `${SIDE_WIDTH_MIN}px`;
const SIDE_WIDTH_BASE = 280;
const SIDE_WIDTH_BASE_PX = `${SIDE_WIDTH_BASE}px`;
const SIDE_WIDTH_MAX = 600;
const SIDE_WIDTH_MAX_PX = `${SIDE_WIDTH_MAX}px`;
const SIDE_WIDTH_AUTOFOLD = 200;
const SEPARATE_HEIGHT_BASE = 240;
const MAX_HEIGHT = 600;
const SIDEBAR_DATA_KEY = "sidebarData";

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const BodyContainer = styled.div`
    position: relative;
    display: flex;
    margin: ${Constants.MOBILE_HEADER_HEIGHT}px 0 0 0;
    width: 100%;
    height: calc(100vh - ${Constants.MOBILE_HEADER_HEIGHT}px);
    background-color: var(--color-White);
    overflow: hidden;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin: ${Constants.TABLET_OR_PC_HEADER_HEIGHT}px 0 0 0;
            height: calc(100vh - ${Constants.TABLET_OR_PC_HEADER_HEIGHT}px);
        `};
`;

// 사이드바 레이아웃 프레임 스타일 start //

const SidemenuContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${SIDE_WIDTH_BASE_PX};
    min-width: ${SIDE_WIDTH_MIN_PX};
    max-width: ${SIDE_WIDTH_MAX_PX};
    height: calc(100vh - 56px);
    background-color: var(--color-White);
    resize: none;
    transition: ${({ isResizing }) => (isResizing ? "none" : "all 350ms ease-in-out")};
    z-index: 4;

    &:hover {
        button {
            visibility: initial;
            opacity: 1;
        }
    }

    ${(props) =>
        props.device.isPC &&
        css`
            width: ${SIDE_WIDTH_BASE_PX};
            min-width: ${SIDE_WIDTH_MIN_PX};
            max-width: ${SIDE_WIDTH_MAX_PX};
            position: relative;
            transition: ${({ isResizing }) => (isResizing ? "none" : "all 350ms ease-in-out")};
        `};
`;

const SidemenuContents = styled.div`
    height: 100%;
    visibility: ${({ isFold }) => (isFold ? "none" : "visible")};
    opacity: ${({ isFold }) => (isFold ? "0" : "1")};
    overflow: hidden;
    transition: ${({ isFold }) => (isFold ? "all 100ms ease-in" : "all 100ms ease-in 220ms")};
`;

const ResizerWrap = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    resize: horizontal;
    user-select: none;

    ${(props) =>
        props.device.isPC &&
        css`
            &:hover {
                cursor: ${({ isFold }) => (isFold ? "default !important" : "ew-resize !important")};

                > span {
                    background-color: ${({ isFold, isHovering }) =>
                        !isFold && isHovering ? "var(--color-Key)" : "transparent"};
                    opacity: ${({ isFold }) => (isFold ? "0" : "1")};
                }

                .toggleButton {
                    background-color: var(--color-Key);
                }
            }
        `};
`;

const SideLine = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -1px;
    width: 3px;
    background-color: var(--color-Grey3);
    opacity: 0.5;
    transition-duration: 0.22s;
    transition-property: left, opacity, width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
`;

const ResizerLine = styled.span`
    position: relative;
    right: 1px;
    display: block;
    width: 3px;
    height: 100%;
    opacity: 0;
    background-color: transparent;
    transition: all 200ms ease;
    pointer-events: none;

    ${(props) =>
        props.device.isPC &&
        css`
            opacity: ${({ isHovering }) => (isHovering ? "1" : "0")};
            background-color: ${({ isHovering }) => (isHovering ? "var(--color-Key)" : "transparent")};
        `};
`;

const ToggleButton = styled.button`
    position: absolute;
    top: 50%;
    right: -36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: var(--color-Grey2);
    border: 0px;
    border-radius: 50%;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    visibility: initial;
    opacity: 1;
    outline: 0;
    cursor: pointer;
    transition: background-color 200ms ease, opacity 200ms ease, box-shadow 200ms ease;

    &:hover {
        background-color: var(--color-Key);
        box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 4px 4px, rgba(9, 30, 66, 0.08) 0px 2px 4px 4px;
    }

    ${(props) =>
        props.device.isPC &&
        css`
            visibility: ${({ isHovering }) => (isHovering ? "initial" : "none")};
            opacity: ${({ isHovering }) => (isHovering ? "1" : "0")};
        `};
`;

// 사이드바 레이아웃 프레임 스타일 end //

// 사이드바 내부 스타일 start //

const MenuSection = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    min-width: 200px;
    height: 48px;

    > a {
        width: 100%;
    }
`;

const SidemenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 4px 0 8px;
    width: calc(100% - 16px);
    height: 32px;
    background-color: ${(props) => (props.isActive ? "var(--color-ButtonHover4)" : "var(--color-White)")};
    border: transparent;
    border-radius: 4px;
    outline: transparent;
    cursor: pointer;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const ItemLeftSide = styled.div`
    display: flex;
    align-items: center;
`;

const ReferenceContainer = styled.div`
    position: relative;
    margin: 8px 0;
    width: 100%;
    min-width: 200px;
    min-height: 120px;
    background-color: var(--color-White);
`;

const ReferenceTagContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 8px;
    width: calc(100% - 16px);
    min-height: 120px;
`;

const ResearchContainer = styled.div`
    position: relative;
    top: 8px;
    margin: 0 0 24px 0;
    width: 100%;
    min-width: 200px;
    min-height: 120px;
    background-color: var(--color-White);
`;

const ResizerVWrap = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: var(--color-White);
    resize: vertical;
    user-select: none;

    &:hover {
        span {
            background-color: ${({ isVHovering }) => (isVHovering ? "var(--color-Key)" : "transparent")};
        }
    }
`;

const SeparateLine = styled.div`
    position: absolute;
    left: 0;
    right: 0px;
    bottom: 8px;
    width: 100%;
    height: 3px;
    background-color: var(--color-Grey3);
    opacity: 0.5;
    transition-duration: 0.22s;
    transition-property: left, opacity, width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
`;

const ResizerVButton = styled.div`
    width: 100%;
    height: 16px;
    padding: 0px;
    background-color: transparent;
    border: 0px;
    cursor: ns-resize !important;

    &:hover {
        span {
            background-color: var(--color-Key);
        }
    }
`;

const ResizerVLine = styled.span`
    position: relative;
    top: 6px;
    display: block;
    width: 100%;
    height: 3px;
    opacity: ${({ isVHovering }) => (isVHovering ? "1" : "0")};
    background-color: ${({ isVHovering }) => (isVHovering ? "var(--color-Key)" : "transparent")};
    transition: all 200ms ease;
    pointer-events: none;
`;

const ResearchListContainer = styled.div`
    margin: 8px 8px 8px 16px;
    height: calc(100vh - ${(props) => (props.separatebarHeight ? props.separatebarHeight : 130)}px);
    overflow-y: scroll;
`;

const ResearchItemWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1000;
`;

const ResearchItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: ${({ isCurrent }) => (isCurrent ? "var(--color-Button4)" : "transparent")};
    border: solid 1px var(--color-White);
    transition: 150ms ease-in;

    &:hover {
        cursor: pointer;
        background-color: ${({ isCurrent }) => (isCurrent ? "var(--color-ButtonHover4)" : "var(--color-Button4)")};
    }
`;

const ResearchItemIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
`;

const ResearchLinkWrap = styled.div`
    margin-left: auto;
`;

// 사이드바 내부 스타일 end //

// 페이지 내용 들어갈 부분 start //

const MainContentsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const MainContentsWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
`;

const DimLayer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    visibility: ${({ isFold }) => (isFold ? "none" : "visible")};
    opacity: ${({ isFold }) => (isFold ? "0" : "1")};
    transition: ${({ isFold }) => (isFold ? "none" : "all 200ms ease-in")};
    z-index: ${({ isFold }) => (isFold ? "-100" : "3")};

    ${(props) =>
        props.device.isPC &&
        css`
            display: none;
        `};
`;

const MainContents = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: ${(props) => props.minWidth || "671px"};
    background-color: var(--color-White);
`;

// 페이지 내용 들어갈 부분 end //

const menuTitleStyle = {
    size: "small",
    margin: "0 0 0 12px",
};

function LoginUserLayout(props) {
    const device = useDevice();
    const wBarRef = useRef(null);
    const hBarRef = useRef(null);
    const researchListRef = useRef(null);

    const userQuery = useUser();
    const [user, setUser] = useState({});

    const [isFold, setIsFold] = useState(false);

    const [wBarSize, setWBarSize] = useState(SIDE_WIDTH_BASE);
    const [wBarResize, setWBarResize] = useState(undefined);
    const [wBarHover, setWBarHover] = useState(false);

    const [hBarSize, setHBarSize] = useState(SEPARATE_HEIGHT_BASE);
    const [hBarResize, setHBarResize] = useState(undefined);
    const [hBarHover, setHBarHover] = useState(false);

    const [researchList, setResearchList] = useState([]);

    const researchQuery = useResearchNameList({
        order: "order",
        is_bookmarked: true,
    });
    const tagQuery = useTags();
    const [viewState, setViewState] = useState({
        [Constants.TAG_TREE_ID_SIDEMENU]: {
            selectedItems: [],
        },
    });
    const navigate = useNavigate();
    const location = useLocation();

    const filter = location.state?.filter;

    useEffect(() => {
        const current_filter = filter ? filter : null;

        const selected_tag_names = [];
        if (current_filter !== null) {
            const matches = current_filter.matchAll(Constants.SEARCH_FILTER_REGEX);
            for (const match of matches) {
                let prefix = match[1];
                let keywords = match[2].trim();

                if (keywords.length > 0) {
                    if (keywords.startsWith('"') && keywords.endsWith('"')) {
                        keywords = keywords.substring(1, keywords.length - 1);
                    }

                    if (prefix === "t:") {
                        selected_tag_names.push(keywords);
                    }
                }
            }
        }

        const selected_tag_ids = selected_tag_names
            .map((tag_name) => tagQuery.data?.find((tag) => tag.name === tag_name)?.id)
            .filter((tag_id) => tag_id !== undefined)
            .map((tag_id) => parseInt(tag_id, 10));

        setViewState((prev) => ({
            ...prev,
            [Constants.TAG_TREE_ID_SIDEMENU]: {
                selectedItems: selected_tag_ids,
            },
        }));
    }, [tagQuery.data, filter]);

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
    }, [userQuery.data]);

    useEffect(() => {
        if (researchQuery.data) {
            setResearchList(researchQuery.data.sort((a, b) => a.order - b.order));
        }
    }, [researchQuery.data]);

    const onSortEnd = (oldIndex, newIndex) => {
        if (researchList && researchList.length !== 0) {
            // 만약 순서가 변하지 않았다면 아무것도 하지 않는다.
            if (oldIndex === newIndex) {
                return;
            }

            // 순서 이동 프론트 처리
            const newResearchList = arrayMoveImmutable(researchList, oldIndex, newIndex).map((research, index) => {
                research.order = index;
                return research;
            });
            setResearchList(newResearchList);

            // 순서 이동 백엔드 처리
            let researchIdList = newResearchList.map((research) => research.id);
            axios.post(apiUrl("researchOrder"), { research_ids: researchIdList });
        }
    };

    // 사이드메뉴 리사이즈
    useEffect(() => {
        loadSidebarData();

        // 브라우저 창 크기가 변했을 경우 처리
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const handleWindowResize = () => {
        if (window.innerWidth < Constants.PC_WIDTH) {
            saveSidebarFold(true);
            setIsFold(true);
            loadSidebarData();
        } else {
            saveSidebarFold(false);
            setIsFold(false);
            loadSidebarData();
        }
    };

    useEffect(() => {
        // 리사이즈 후 특정 너비보다 작으면 자동으로 fold 상태로 전환
        if (wBarResize === false) {
            if (wBarSize < SIDE_WIDTH_AUTOFOLD) {
                setIsFold(true);
                setWBarSize(SIDE_WIDTH_MIN);
                saveSidebarSize();
                saveSidebarFold(true);
            }
        }
    }, [wBarResize]);

    const startWBarResizing = () => {
        if (!isFold) {
            setWBarResize(true);
            window.addEventListener("mousemove", applyWBarResizing);
            window.addEventListener("mouseup", stopWBarResizing);
        }
    };

    const applyWBarResizing = (e) => {
        if (wBarRef.current) {
            const newWidth = e.clientX - wBarRef.current.getBoundingClientRect().left;
            const clampedWidth = Math.min(Math.max(newWidth, 1), SIDE_WIDTH_MAX);
            setWBarSize(clampedWidth);
        }
    };

    const stopWBarResizing = () => {
        if (!isFold) {
            setWBarResize(false);
            window.removeEventListener("mousemove", applyWBarResizing);
            window.removeEventListener("mouseup", stopWBarResizing);

            saveSidebarSize();
        }
    };

    const startHBarResizing = () => {
        if (!isFold) {
            setHBarResize(true);
            window.addEventListener("mousemove", applyHBarResizing);
            window.addEventListener("mouseup", stopHBarResizing);
        }
    };

    const stopHBarResizing = () => {
        if (!isFold) {
            setHBarResize(false);
            window.removeEventListener("mousemove", applyHBarResizing);
            window.removeEventListener("mouseup", stopHBarResizing);

            saveSidebarSize();
        }
    };

    const applyHBarResizing = (e) => {
        if (hBarRef.current) {
            const newHeight = e.clientY - hBarRef.current.getBoundingClientRect().top;
            const clampedHeight = Math.min(Math.max(newHeight, 1), MAX_HEIGHT);
            setHBarSize(clampedHeight);
        }
    };

    const toggleSidebar = (e) => {
        e.stopPropagation();

        if (isFold) {
            setIsFold(false);
            saveSidebarFold(false);
            loadSidebarData();
        } else {
            setIsFold(true);
            saveSidebarFold(true);
            loadSidebarData();
        }
    };

    const saveSidebarSize = () => {
        let oldData = JSON.parse(localStorage.getItem(SIDEBAR_DATA_KEY));

        let w_px = wBarRef.current.style.width;
        let h_px = hBarRef.current.style.height;
        let w = typeof w_px === "string" ? parseInt(w_px.substring(0, w_px.length - 2)) : w_px;
        let h = typeof h_px === "string" ? parseInt(h_px.substring(0, h_px.length - 2)) : h_px;

        let sidebarData = JSON.stringify({
            w: w,
            h: h,
            f: oldData?.f || false,
        });

        localStorage.setItem(SIDEBAR_DATA_KEY, sidebarData);
    };

    const saveSidebarFold = (fold) => {
        let oldData = JSON.parse(localStorage.getItem(SIDEBAR_DATA_KEY));

        let sidebarData = JSON.stringify({
            w: oldData?.w || SIDE_WIDTH_BASE,
            h: oldData?.h || SEPARATE_HEIGHT_BASE,
            f: fold,
        });

        localStorage.setItem(SIDEBAR_DATA_KEY, sidebarData);
    };

    const loadSidebarData = () => {
        let sidebarData = JSON.parse(localStorage.getItem(SIDEBAR_DATA_KEY));

        let f = sidebarData?.f || false;
        let w = sidebarData?.w || SIDE_WIDTH_BASE;
        let h = sidebarData?.h || SEPARATE_HEIGHT_BASE;

        if (f) {
            w = SIDE_WIDTH_MIN;
        } else {
            w = Math.max(w, SIDE_WIDTH_BASE);
        }

        setIsFold(f);
        setWBarSize(w);
        setHBarSize(h);
    };

    const onClickNavItem = (url) => {
        if (window.innerWidth < Constants.PC_WIDTH) {
            saveSidebarFold(true);
            setIsFold(true);
            setWBarSize(SIDE_WIDTH_MIN);
        }
        navigate(url);
    };

    return (
        <Container>
            <PageHeader />
            <BodyContainer device={device}>
                <SidemenuContainer
                    device={device}
                    ref={wBarRef}
                    isFold={isFold}
                    isResizing={wBarResize}
                    style={{
                        width: `${wBarSize}px`,
                    }}
                >
                    <SidemenuContents isFold={isFold}>
                        <MenuSection>
                            <SidemenuItem
                                isActive={location.pathname.startsWith("/search")}
                                onClick={(e) => {
                                    onClickNavItem("/search");
                                }}
                            >
                                <ItemLeftSide>
                                    <Icon name={"sideBarSearch"} size={"16px"} />
                                    <TitleText {...menuTitleStyle}>논문 DB 검색</TitleText>
                                </ItemLeftSide>
                            </SidemenuItem>
                        </MenuSection>
                        <ReferenceContainer>
                            <SidemenuItem
                                isActive={location.pathname.startsWith("/reference")}
                                onClick={(e) => {
                                    onClickNavItem("/reference");
                                }}
                            >
                                <ItemLeftSide>
                                    <Icon name={"sideBarReference"} size={"16px"} />
                                    <TitleText {...menuTitleStyle}>참고문헌 관리</TitleText>
                                </ItemLeftSide>
                            </SidemenuItem>
                            <ReferenceTagContainer ref={hBarRef} isVResizing={hBarResize} style={{ height: hBarSize }}>
                                <ReferenceTagTreeView
                                    treeId={Constants.TAG_TREE_ID_SIDEMENU}
                                    viewState={viewState}
                                    onSelectItems={(items) => {
                                        if (tagQuery.data) {
                                            const current_filter = filter ? filter : null;
                                            const search_filters = tagQuery.data
                                                .filter((tag) => items.includes(tag.id))
                                                .map((tag) => 't:"' + tag.name + '"');

                                            if (current_filter !== null) {
                                                let matches = current_filter.matchAll(Constants.SEARCH_FILTER_REGEX);
                                                for (const match of matches) {
                                                    let prefix = match[1];
                                                    let keywords = match[2].trim();

                                                    if (keywords.length > 0) {
                                                        if (keywords.startsWith('"') && keywords.endsWith('"')) {
                                                            keywords = keywords.substring(1, keywords.length - 1);
                                                        }

                                                        // 즐겨찾기 유지
                                                        if (prefix + keywords === "is:bookmarked") {
                                                            search_filters.push("is:bookmarked");
                                                        }

                                                        // 리서치 유지
                                                        if (prefix === "r:") {
                                                            search_filters.push('r:"' + keywords + '"');
                                                        }

                                                        // 일반 키워드 유지
                                                        if (prefix === undefined) {
                                                            search_filters.push(
                                                                keywords.includes(" ")
                                                                    ? '"' + keywords + '"'
                                                                    : keywords,
                                                            );
                                                        }
                                                    }
                                                }
                                            }

                                            if (search_filters.length > 0) {
                                                navigate("/reference", {
                                                    state: {
                                                        filter: search_filters.join(" "),
                                                    },
                                                });
                                            } else {
                                                if (location.pathname === "/reference") {
                                                    // 태그 선택을 모두 취소한 경우에 대한 특수 처리
                                                    navigate("/reference");
                                                }
                                            }
                                        }
                                    }}
                                />
                            </ReferenceTagContainer>
                            <ResizerVWrap
                                isVHovering={hBarHover}
                                onMouseDown={startHBarResizing}
                                onMouseEnter={(e) => setHBarHover(true)}
                                onMouseLeave={(e) => setHBarHover(false)}
                            >
                                <SeparateLine />
                                <ResizerVButton>
                                    <ResizerVLine isVHovering={hBarHover} />
                                </ResizerVButton>
                            </ResizerVWrap>
                        </ReferenceContainer>
                        <ResearchContainer>
                            <SidemenuItem
                                isActive={location.pathname.startsWith("/research")}
                                onClick={() => {
                                    onClickNavItem("/research");
                                }}
                                ref={researchListRef}
                            >
                                <ItemLeftSide>
                                    <Icon name={"sideBarResearch"} size={"16px"} />
                                    <TitleText {...menuTitleStyle}>리서치 관리</TitleText>
                                </ItemLeftSide>
                            </SidemenuItem>
                            <ResearchListContainer
                                separatebarHeight={researchListRef?.current?.getBoundingClientRect().bottom + 10}
                            >
                                <TitleText
                                    size={"subSmall"}
                                    fontWeight={"regular"}
                                    color={"var(--color-Grey2)"}
                                    margin={"0px 0px 8px 0px"}
                                >
                                    {"즐겨찾기된 리서치"}
                                </TitleText>
                                <SortableList onSortEnd={onSortEnd} distance={2}>
                                    {researchList?.map((research, index) => (
                                        <SortableItem key={index}>
                                            <ResearchItemWrap>
                                                <ResearchItem
                                                    className={"sortableHelper"}
                                                    isCurrent={
                                                        window.location.pathname.split("/")[2] === research.uuid &&
                                                        window.location.pathname.split("/")[1] === "research"
                                                    }
                                                >
                                                    <ResearchItemIcon>
                                                        <Icon name={"sideBarResearchItem"} size={"16px"} />
                                                    </ResearchItemIcon>
                                                    <GeneralText
                                                        size={"regular"}
                                                        margin={"0 0 0 8px"}
                                                        width={"100% - 24px"}
                                                        textAlign={"justify"}
                                                    >
                                                        {research.display_name}
                                                    </GeneralText>
                                                    <ResearchLinkWrap>
                                                        <Tooltip message={"리서치로 이동"}>
                                                            <Button
                                                                onlyIcon
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onClickNavItem(`/research/${research.uuid}`);
                                                                }}
                                                                bgColor={"transparent"}
                                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                            >
                                                                <Icon name={"arrowRight"} size={"10px"} />
                                                            </Button>
                                                        </Tooltip>
                                                    </ResearchLinkWrap>
                                                </ResearchItem>
                                            </ResearchItemWrap>
                                        </SortableItem>
                                    ))}
                                </SortableList>
                            </ResearchListContainer>
                        </ResearchContainer>
                    </SidemenuContents>
                    <ResizerWrap
                        device={device}
                        isFold={isFold}
                        isHovering={wBarHover}
                        onMouseDown={startWBarResizing}
                        onMouseEnter={(e) => setWBarHover(true)}
                        onMouseLeave={(e) => setWBarHover(false)}
                    >
                        <SideLine />
                        <ResizerLine className="resizer" device={device} isHovering={wBarHover} isFold={isFold} />
                        <ToggleButton
                            className="toggleButton"
                            device={device}
                            isFold={isFold}
                            isHovering={wBarHover}
                            onMouseDown={toggleSidebar}
                        >
                            <Icon
                                name={isFold ? "sidemenuOpen" : "sidemenuClose"}
                                size={"16"}
                                color={"var(--color-White)"}
                            />
                        </ToggleButton>
                    </ResizerWrap>
                </SidemenuContainer>
                <MainContentsContainer
                    style={{
                        width: `calc(100% - ${wBarSize}px)`,
                    }}
                >
                    {user.file_total_size >= 200 * Constants.MB && user.plan_id === Constants.FREE_PLAN && (
                        <AlertBanner
                            alertMessage="저장 공간을 모두 사용하여 참고문헌 및 리서치 추가가 불가능합니다. 구독중인 요금제를 확인해주세요."
                            showPlan={true}
                        />
                    )}
                    {!device.isTabletOrPC && (
                        <AlertBanner alertMessage="원활한 이용을 위해 데스크탑 환경을 권장드립니다." showPlan={false} />
                    )}
                    <MainContentsWrap>
                        <DimLayer
                            device={device}
                            isFold={isFold}
                            onClick={(e) => {
                                setIsFold(true);
                                setWBarSize(SIDE_WIDTH_MIN);
                            }}
                        />
                        <MainContents {...props}>{props.children}</MainContents>
                    </MainContentsWrap>
                </MainContentsContainer>
            </BodyContainer>
        </Container>
    );
}

export default LoginUserLayout;
