import { useMutation, useQuery, useQueryClient } from "react-query";

import Confirm from "components/atoms/alert/Confirm";
import useToast from "components/atoms/toast/useToast";

import {
    citationReference,
    deleteBulkResearch,
    deleteReferenceCitation,
    deleteReferenceCitationByUuid,
    deleteReferenceResearch,
    deleteResearch,
    deleteResearchMedia,
    getResearch,
    getResearchList,
    getResearchMedia,
    patchResearch,
    postReferenceResearch,
    postResearch,
    postResearchMedia,
    postResearchWithHtml,
} from "utils/request/research";

export const useResearches = (params) => {
    return useQuery("researches", (e) => getResearchList(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const useResearchNameList = () => {
    return useQuery(
        "researchNameList",
        (e) =>
            getResearchList({
                order: "order",
                is_bookmarked: true,
            }),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
        },
    );
};

export const useResearch = (params) => {
    return useQuery("research", (e) => getResearch(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const useResearchDelete = (params) => {
    const queryClient = useQueryClient();

    return useMutation(deleteResearch, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "researches" });
            queryClient.invalidateQueries({ queryKey: "researchNameList" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
        },
    });
};

export const useResearchBulkDelete = (params) => {
    const queryClient = useQueryClient();

    return useMutation(deleteBulkResearch, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "researches" });
            queryClient.invalidateQueries({ queryKey: "researchNameList" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
        },
    });
};

export const useResearchCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(postResearch, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "researches" });
            queryClient.invalidateQueries({ queryKey: "researchNameList" });
        },
    });
};

export const useResearchUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation(patchResearch, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            queryClient.invalidateQueries({ queryKey: "research" });
            queryClient.invalidateQueries({ queryKey: "researches" });
            queryClient.invalidateQueries({ queryKey: "researchNameList" });
        },
    });
};

export const useResearchReferencesCreate = (body) => {
    const queryClient = useQueryClient();
    const { setToast } = useToast();

    return useMutation(postReferenceResearch, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "researchReferences" });
            queryClient.invalidateQueries({ queryKey: "researches" });
            queryClient.invalidateQueries({ queryKey: "research" });
            queryClient.invalidateQueries({ queryKey: "bibliography" });
            queryClient.invalidateQueries({ queryKey: "bibliographies" });
            setToast("적용되었습니다.", "info");
        },
    });
};

export const useReferenceCitationCreate = (body) => {
    return useMutation(citationReference, {
        onSuccess: (data, variables, context) => {},
    });
};

export const useReferenceCitationDelete = (body) => {
    return useMutation(deleteReferenceCitation, {
        onSuccess: (data, variables, context) => {},
    });
};

export const useReferenceCitationDeleteByUuid = (body) => {
    const queryClient = useQueryClient();
    return useMutation(deleteReferenceCitationByUuid, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "research" });
        },
    });
};

export const useReferenceResearchDelete = (body) => {
    return useMutation(deleteReferenceResearch, {
        onSuccess: (data, variables, context) => {},
    });
};

export const useResearchMedia = (params) => {
    return useQuery("researchMedia", (e) => getResearchMedia(params), {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
    });
};

export const useResearchMediaCreate = (body) => {
    const queryClient = useQueryClient();
    return useMutation(postResearchMedia, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "researchMedia" });
        },
    });
};

export const useResearchMediaBulkDelete = (body) => {
    const queryClient = useQueryClient();
    return useMutation(deleteResearchMedia, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "researchMedia" });
        },
    });
};

export const useResearchWidthHtmlCreate = () => {
    const queryClient = useQueryClient();

    return useMutation(postResearchWithHtml, {
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: "researches" });
            queryClient.invalidateQueries({ queryKey: "researchNameList" });
            Confirm(
                "info",
                "알림",
                "리서치 노트로 저장되었습니다. \n지금 리서치 상세로 이동하시겠습니까?",
                "이동",
                () => {
                    window.location.href = `/research/${data.uuid}`;
                },
            );
        },
    });
};
