import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Tooltip from "components/atoms/Tooltip";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const TableCell = styled.td`
    z-index: 0;
`;

const TableCellWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    padding-left: 4px;
`;

const TableCellText = styled.div`
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.03em;
    font-size: 1.2rem;
    word-break: break-all;
`;

const TableCellContent = styled.div`
    overflow: hidden;
`;

const Td = ({ cell }) => {
    const { isDragging, setNodeRef, transform } = useSortable({
        id: cell.column.id,
    });

    const textRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        if (textRef.current) {
            const scrollHeight = textRef.current.scrollHeight;
            const offsetHeight = textRef.current.offsetHeight;
            setIsOverflowing(scrollHeight > offsetHeight);
        }
    }, [textRef?.current?.clientWidth, cell]);

    const style = {
        opacity: isDragging ? 0.8 : 1,
        padding: "8px 6px",
        height: "48px",
        transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
        transition: "width transform 0.2s ease-in-out",
        zIndex: isDragging ? 1 : 0,
        color: "var(--color-SubBlack)",
    };

    const text = cell.column.columnDef.text ? cell.column.columnDef.text(cell.getContext()) : "";
    const content = cell.column.columnDef.content ? cell.column.columnDef.content(cell.getContext()) : "";

    return (
        <TableCell
            key={cell.id}
            ref={setNodeRef}
            style={{
                ...style,
                width: cell.column.getSize(),
            }}
        >
            <TableCellWrap>
                {isOverflowing ? (
                    <>
                        <Tooltip message={text}>
                            <TableCellText ref={textRef}>{text}</TableCellText>
                        </Tooltip>
                        <TableCellContent>{content}</TableCellContent>
                    </>
                ) : (
                    <>
                        <TableCellText ref={textRef}>{text}</TableCellText>
                        <TableCellContent>{content}</TableCellContent>
                    </>
                )}
            </TableCellWrap>
        </TableCell>
    );
};

export default Td;
